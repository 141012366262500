<template>
  <v-app>
    <AppHeaderComp />

    <v-main>
      <!-- <AppMenuComp /> -->
      <v-container>
        <router-view :key="`${$route.path}-${lg}`" />
      </v-container>
    </v-main>
    <AppFooterComp />
  </v-app>
</template>
<script>
import Vuex from 'vuex';

export default {
  title: (thiss) => {
    return {
      en: 'Home page',
      fr: "Page d'accueil",
      ar: 'الصفحة الرئيسية',
    }[thiss.lg];
  },
  data() {
    return {
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' },
      ],
      // alertKey: "alertKey",
      // a: 111,
      // dialog: false,
      // dataConsole: [],
      //                 TheComp_example: {comp:['a','b'],///title////
      // props: {
      // key: 'key-season-sec'},
      // a:{
      // comp: "VCard",
      // props: {
      // key: 'key-season-sec',
      // // hide: thiss => {
      // // let u = thiss.user;
      // // return !(u.is_staff | u.is_federation | u.is_governoment | u.is_ministry | u.is_club)
      // // },
      // html: 'ddddddddddddddd',
      // class: "red text-h5 justify-center text-darken-1 my-5 ",
      // // style: {},
      // // html: thiss => { return { en: 'Club choice', fr: 'Choix du club', ar: 'اختيار النادي' } [thiss.lg] },
      // }},
      // b:'[[bla bla bla||aaaaaaaaaaa]]'
      // }
    };
  },
  components: {
    AppHeaderComp: () => import('./components/appHeader/AppHeaderComp.vue'),
    AppMenuComp: () => import('./components/appMenu/AppMenuComp.vue'),
    AppFooterComp: () => import('./components/appFooter/AppFooterComp.vue'),
    AppSideBarComp: () => import('./components/appMenu/AppSideBarComp.vue'),

    // DialogGeneralModelMyAlertComp: () => import("@/components/generalComps/myAlertComp/DialogGeneralModelMyAlertComp")
  },
  methods: {
    ...Vuex.mapActions(['setLang']),
    //     hideAlert(){
    //       this.$store.commit('updateMyAlertCondition', false)
    // this.$store.commit('updateMyAlertObjs', {})
    //     },
    // show () {
    //         this.$modal.show('my-first-modal');
    //     },
    //     hide () {
    //         this.$modal.hide('my-first-modal');
    //     }
  },
  computed: {
    ...Vuex.mapGetters([
      'urlServer',
      'user',
      'loggedIn',
      'lg',
      'headers',
      'globalLibelle',
      'token',
      'cryptoKey',
      'initialCryptoKey',
      'myAlertCondition',
      'myAlertObjs',
      'myAlertObjsTitles',
    ]),
    // mAlertCondition: function (){
    //   this.alertKey+=1
    //   return JSON.parse((this.$cookies.get('mAlertCondition')));
    // }
  },
  watch: {
    // dialog(v){
    //   this.dialog=v;
    //   this.$store.commit('myAlertCondition', v)
    // }
    // myAlertCondition(v){
    //   this.dialog=v;
    //   this.$store.commit('myAlertCondition', v)
    // }
  },
  created() {
    // const link = document.querySelector("[rel='icon']");
    // link.setAttribute('href', 'http://www.ftt.tn/images/logoClub/Logo_TCMO.jpg');
    // link.setAttribute('Atype', 'image/x-icon');
    // this.tokenAccessCheck();
  },
  mounted() {
    // this.show()
    // this.dialog=this.myAlertCondition;
    // alert(this.$cookies.get('lg'));
    if (!this.$cookies.get('lg')) {
      this.$cookies.set('lg', 'en');
    }
    this.$store.commit('updateLang', this.$cookies.get('lg'));
    global.v = this;
    // },
    /////////////////Check if user is still login/////////////////
  },
  computed: {
    ...Vuex.mapGetters([
      'urlServer',
      'user',
      'profile',
      'loggedIn',
      'lg',
      'headers',
      'globalLibelle',
      'token',
      'cryptoKey',
      'club',
    ]),
  },
};
</script>
