import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// let u = store.state.user;

////////////////////////////////////////////
// import TheComp from "@/components/theComps/theItemsTableComp/TheTheComp.vue";
// import ItemsTableComp from "@/components/theComps/theItemsTableComp/TheItemsTableComp.vue";
///////////////////////////////////////////
// var isLoggedIn = store.getters.loggedIn

Vue.use(VueRouter);
const routes = [

    ////////////////////////// ui general pages ////////////////////////////////
    {
        path: "/",
        name: "HomeView",
        component: () => import("../views/ui/home/HomeView.vue"),
        // props:false
    },
    {
        path: "/help",
        name: "HelpView",
        // component: HelpView
        component: () => import(/* webpackChunkName:"HelpView" */ "@/views/ui/help/HelpView.vue"),
        meta: {
            requiresAuth: !true,
        },
    },

    /////////////////////////////////// ui/account ////////////////////////////////////////////////////////
    {
        path: "/login",
        name: "Login",
        component: () => import("../views/ui/account/login/Login.vue"),
        query: {},
        meta: {
            requiresAuth: false,
        },
        //         beforeEnter(to, from, next) {
        //   // console.log('rrrrrrrrr', to, from, u)

        //               if(u)
        //     {
        //       alert('ssssssssss')
        //   // alert(store.state.user.email);

        //       // next({ name: 'Home' });
        //   }
        //     next()
        // }
    },
    {
        path: "/logout",
        name: "LogOut",
        component: () => import("../views/ui/account/logout/LogOut.vue"),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("../views/ui/account/register/Register.vue"),
        meta: {
            requiresAuth: false,
            // permissions: ['isSuperuser', 'isStaff', 'isActive'],
            // groupes: "management"
        },
    },
    {
        path: "/finish",
        name: "FinishRegistration",
        component: () => import("../views/ui/account/finishRegistration/FinishRegistration.vue"),

        meta: {
            requiresAuth: true,
            // permissions: ['isSuperuser', 'isStaff', 'isActive'],
            // groupes: "management"
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: () => import("../views/ui/account/profile/Profile.vue"),
        meta: {
            requiresAuth: true,
            // permissions: ['isSuperuser', 'isStaff', 'isActive'],
            // groupes: "management"
        },
    },
    {
        path: "/account/activate/:uidb64/:token",
        name: "ActivateUser",
        component: () => import("../views/settings/ActivateUser.vue"),
        // props: true,
    },
    /////////////////////////////////// ui/timetable ////////////////////////////////////////////////////////

];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // linkActiveClass:'t-c-active-link'
});

// router.afterEach((to, from) => {
//     alert("9999999999999999999999999999999999999999999999999");
//     store._vm.console({ from, title: "form", propss:{color: "green"} }, { to, title: "to" }, { next, title: "next" });
// });
router.beforeEach((to, from, next) => {
    let u = store.state.user;

    store._vm.console(
        { from, title: "form", propss: { color: "yellow" } },
        { to, title: "to" },
        { next, title: "next" }
    );
    store._vm.console(
        { from, title: "form", propss: { color: "black" } },
        { to, title: "to" },
        { next, title: "next" }
    );
    store._vm.console(
        { from, title: "form", propss: { color: "green" } },
        { to, title: "to" },
        { next, title: "next" }
    );
    // store._vm.console({ from, title: "form", propss:{color: "yellow"} });
    // alert(JSON.stringify(u));
    console.log("dddddddddddd", "store", store, "to:", to, "from:", from, "next:", next, "user:", u);
    if (to.name != "Login" && to.name !== "FinishRegistration" && to.meta.requiresAuth) {
        if (u) {
            // alert(to.meta.requiresAuth);
            // if () {
            if (!u?.email || u.email.indexOf("fake") >= 0) {
                next({ name: "FinishRegistration" });
            } else if (to.query.redirect) {
                next({ name: "Login", query: { redirect: to.path } });
            }
        } else if (to.meta.requiresAuth) {
            next({ name: "Login", query: { redirect: to.path } });
            // next({ name: 'NotFound', query: { redirect: '4jjjjj' }, hash: '#vdvsds' });
        }
        next();
    }
    next(); // This is where it should have been
});

function dateIsValid(dateStr) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (dateStr.match(regex) === null) {
        return false;
    }

    const date = new Date(dateStr);

    const timestamp = date.getTime();

    if (typeof timestamp !== "number" || Number.isNaN(timestamp)) {
        return false;
    }

    return date.toISOString().startsWith(dateStr);
}

export default router;
