let tags_html_by_browser = {
    a: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            download: {
                browser: {
                    chr: 14.0,
                    ie: 18.0,
                    fx: 20.0,
                    saf: 10.1,
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            href: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            hreflang: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            media: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            ping: {
                browser: {
                    chr: true,
                    ie: false,
                    fx: true,
                    saf: false,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            referrerpolicy: {
                browser: {
                    chr: 51.0,
                    ie: 79.0,
                    fx: 50.0,
                    saf: 11.1,
                    op: 38.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            rel: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            target: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
        },
    },
    abbr: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    address: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    area: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            alt: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            coords: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            download: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            href: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            hreflang: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            media: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            referrerpolicy: {
                browser: {
                    chr: 51.0,
                    ie: 79.0,
                    fx: 50.0,
                    saf: 11.1,
                    op: 38.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            rel: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            shape: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            target: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
        },
    },
    article: {
        browser: {
            chr: 6.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.0,
            op: 11.1,
        },
        attrs: {},
    },
    aside: {
        browser: {
            chr: 6.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.0,
            op: 11.1,
        },
        attrs: {},
    },
    audio: {
        browser: {
            chr: 4.0,
            ie: 9.0,
            fx: 3.5,
            saf: 4.0,
            op: 11.5,
        },
        attrs: {
            autoplay: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 4.0,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            controls: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 4.0,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            loop: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 4.0,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            muted: {
                browser: {
                    chr: 4.0,
                    ie: 10.0,
                    fx: 11.0,
                    saf: 7.1,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            preload: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 4.0,
                    saf: 4.0,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            src: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 4.0,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    b: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    base: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            href: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
            target: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
        },
    },
    bdi: {
        browser: {
            chr: 16.0,
            ie: 79.0,
            fx: 10.0,
            saf: false,
            op: 15.0,
        },
        attrs: {},
    },
    bdo: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            dir: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: {},
            },
        },
    },
    blockquote: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            cite: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
            },
            desc: "",
            note: "",
            concern: [],
        },
    },
    body: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    br: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    button: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            autofocus: {
                browser: {
                    chr: 5.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.0,
                    op: 9.6,
                },
                concern: [],
                desc: "",
                note: "",
            },
            disabled: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                desc: "",
                note: "",
                concern: [],
            },
            form: {
                browser: {
                    chr: 10.0,
                    ie: 16.0,
                    fx: 4.0,
                    saf: 5.1,
                    op: 9.5,
                },
                concern: [],
                desc: "",
                note: "",
            },
            formaction: {
                browser: {
                    chr: 9.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.1,
                    op: 15.0,
                },
                concern: [],
                desc: "",
                note: "",
            },
            formenctype: {
                browser: {
                    chr: 9.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.1,
                    op: 11.5,
                },
                concern: [],
                desc: "",
                note: "",
            },
            formmethod: {
                browser: {
                    chr: 9.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.1,
                    op: 15.0,
                },
                concern: [],
                desc: "",
                note: "",
            },
            formnovalidate: {
                browser: {
                    chr: 6.0,
                    ie: 11.0,
                    fx: 4.0,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            formtarget: {
                browser: {
                    chr: 9.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: [],
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            value: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    canvas: {
        browser: {
            chr: 4.0,
            ie: 9.0,
            fx: 2.0,
            saf: 3.1,
            op: 9.0,
        },
        attrs: {
            height: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 2.0,
                    saf: 3.1,
                    op: 9.0,
                },
                concern: [],
                desc: "",
                note: "",
            },
            width: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 2.0,
                    saf: 3.1,
                    op: 9.0,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    caption: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    cite: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    code: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    col: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            span: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    colgroup: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            span: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    data: {
        browser: {
            chr: 62.0,
            ie: 13.0,
            fx: 22.0,
            saf: false,
            op: 49.0,
        },
        attrs: {
            value: {
                browser: {
                    chr: 62.0,
                    ie: 13.0,
                    fx: 22.0,
                    saf: false,
                    op: 49.0,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    datalist: {
        browser: {
            chr: 20.0,
            ie: 10.0,
            fx: 4.0,
            saf: 12.1,
            op: 9.5,
        },
        attrs: {},
    },
    dd: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    del: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            cite: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            datetime: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    details: {
        browser: {
            chr: 12.0,
            ie: 79.0,
            fx: 49.0,
            saf: 6.0,
            op: 15.0,
        },
        attrs: {},
        open: {
            browser: {
                chr: 12.0,
                ie: 79.0,
                fx: 49.0,
                saf: 6.0,
                op: 15.0,
            },
            concern: [],
            desc: "",
            note: "",
        },
    },

    dfn: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    dialog: {
        browser: {
            chr: 37.0,
            ie: 79.0,
            fx: 53.0,
            saf: false,
            op: 24.0,
        },
        attrs: {},
        open: {
            browser: {
                chr: 37.0,
                ie: 79.0,
                fx: 53.0,
                saf: false,
                op: 24.0,
            },
            concern: [],
            desc: "",
            note: "",
        },
    },

    div: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    dl: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    dt: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    em: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    embed: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            height: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            src: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            width: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    fieldset: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            disabled: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: 6.0,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            form: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: 11.0,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    figcaption: {
        browser: {
            chr: 8.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.1,
            op: 11.0,
        },
        attrs: {},
    },

    figure: {
        browser: {
            chr: 8.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.1,
            op: 11.0,
        },
        attrs: {},
    },

    footer: {
        browser: {
            chr: 5.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.0,
            op: 11.1,
        },
        attrs: {},
    },

    form: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            "accept-charset": {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            action: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            autocomplete: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: 4.0,
                    saf: 5.2,
                    op: 15.0,
                },
                concern: [],
                desc: "",
                note: "",
            },
            enctype: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            method: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            novalidate: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 10.1,
                    op: 15.0,
                },
                concern: [],
                desc: "",
                note: "",
            },
            rel: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
            target: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: [],
                desc: "",
                note: "",
            },
        },
    },
    h1: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    h2: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    h3: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    h4: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    h5: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    h6: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    head: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    header: {
        browser: {
            chr: "5.0 and +",
            ie: 9.0,
            fx: 4.0,
            saf: 5.0,
            op: 11.1,
        },
        attrs: {},
    },

    hr: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    html: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            xmlns: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    i: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    iframe: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            allow: {
                browser: {
                    chr: 60.0,
                    ie: 79.0,
                    fx: 74.0,
                    saf: 11.1,
                    op: 47.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            allowfullscreen: {
                browser: {
                    chr: 27.0,
                    ie: "11.0 - ms - ",
                    fx: 18.0,
                    saf: 7.0,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            allowpaymentrequest: {
                browser: {
                    chr: false,
                    ie: false,
                    fx: false,
                    saf: false,
                    op: false,
                },
                concern: {},
                desc: "",
                note: "",
            },
            height: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            referrerpolicy: {
                browser: {
                    chr: 51.0,
                    ie: 79.0,
                    fx: 50.0,
                    saf: 11.1,
                    op: 38.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            sandbox: {
                browser: {
                    chr: 4.0,
                    ie: 10.0,
                    fx: 17.0,
                    saf: 5.0,
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            src: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            srcdoc: {
                browser: {
                    chr: 20.0,
                    ie: 79.0,
                    fx: 25.0,
                    saf: 6.0,
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            width: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    img: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            alt: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            crossorigin: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            height: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            ismap: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            loading: {
                browser: {
                    chr: 77.0,
                    ie: 79.0,
                    fx: 75.0,
                    saf: false,
                    op: 64.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            longdesc: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            referrerpolicy: {
                browser: {
                    chr: 51.0,
                    ie: 79.0,
                    fx: 50.0,
                    saf: 11.1,
                    op: 38.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            sizes: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            src: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            srcset: {
                browser: {
                    chr: 34.0,
                    ie: "?",
                    fx: 38.0,
                    saf: 8.0,
                    op: 21.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            usemap: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            width: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    input: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            accept: {
                browser: {
                    chr: 26.0,
                    ie: 10.0,
                    fx: 37.0,
                    saf: 11.1,
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            alt: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            autocomplete: {
                browser: {
                    chr: 17.0,
                    ie: 6.0,
                    fx: 2.0,
                    saf: 5.1,
                    op: 10.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            autofocus: {
                browser: {
                    chr: 5.0,
                    ie: 11.0,
                    fx: 4.0,
                    saf: 5.0,
                    op: 9.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            checked: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            dirname: {
                browser: {
                    chr: true,
                    ie: 79.0,
                    fx: false,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            disabled: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            form: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            formaction: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: true,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            formenctype: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: true,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            formmethod: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: true,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            formnovalidate: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: true,
                    saf: 10.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            formtarget: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: true,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            height: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: 16.0,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            list: {
                browser: {
                    chr: 20.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: false,
                    op: 9.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            max: {
                browser: {
                    chr: 5.0,
                    ie: 10.0,
                    fx: 16.0,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            maxlength: {
                browser: {
                    chr: 4.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.1,
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            min: {
                browser: {
                    chr: 5.0,
                    ie: 10.0,
                    fx: 16.0,
                    saf: 5.1,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            minlength: {
                browser: {
                    chr: 40.0,
                    ie: 17.0,
                    fx: 51.0,
                    saf: 10.1,
                    op: 27.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            multiple: {
                browser: {
                    chr: 6.0,
                    ie: 10.0,
                    fx: 3.6,
                    saf: 5.0,
                    op: 11.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            pattern: {
                browser: {
                    chr: 5.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 10.1,
                    op: 9.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            placeholder: {
                browser: {
                    chr: 10.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.0,
                    op: 11.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            readonly: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            required: {
                browser: {
                    chr: 5.0,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 10.1,
                    op: 9.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            size: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            src: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            step: {
                browser: {
                    chr: 6.0,
                    ie: 10.0,
                    fx: 16.0,
                    saf: 5.0,
                    op: 10.6,
                },
                concern: {},
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            value: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            width: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: 16.0,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    ins: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            cite: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            datetime: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    kbd: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    label: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            for: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            form: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    legend: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    li: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            value: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    link: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            crossorigin: {
                browser: {
                    chr: 25.0,
                    ie: 79.0,
                    fx: 18.0,
                    saf: "?",
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            href: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            hreflang: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            media: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            referrerpolicy: {
                browser: {
                    chr: 51.0,
                    ie: 79.0,
                    fx: 50.0,
                    saf: 11.1,
                    op: 38.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            rel: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            sizes: {
                browser: {
                    chr: false,
                    ie: false,
                    fx: false,
                    saf: false,
                    op: false,
                },
                concern: {},
                desc: "",
                note: "",
            },
            title: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    main: {
        browser: {
            chr: 26.0,
            ie: 12.0,
            fx: 21.0,
            saf: 7.0,
            op: 16.0,
        },
        attrs: {},
    },

    map: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    mark: {
        browser: {
            chr: 6.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.0,
            op: 11.1,
        },
        attrs: {},
    },

    meta: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            charset: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            content: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            http_equiv: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    meter: {
        browser: {
            chr: 8.0,
            ie: 13.0,
            fx: 16.0,
            saf: 6.0,
            op: 11.5,
        },
        attrs: {},
        form: {
            browser: {
                chr: false,
                ie: false,
                fx: false,
                saf: false,
                op: false,
            },
            concern: {},
            desc: "",
            note: "",
        },
        high: {
            browser: {
                chr: 8.0,
                ie: 13.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
        low: {
            browser: {
                chr: 8.0,
                ie: 13.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
        max: {
            browser: {
                chr: 8.0,
                ie: 13.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
        min: {
            browser: {
                chr: 8.0,
                ie: 13.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
        optimum: {
            browser: {
                chr: 8.0,
                ie: 13.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
        value: {
            browser: {
                chr: 8.0,
                ie: 13.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
    },

    nav: {
        browser: {
            chr: 5.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.0,
            op: 11.1,
        },
        attrs: {},
    },

    noscript: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    object: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            data: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            form: {
                browser: {
                    chr: false,
                    ie: false,
                    fx: false,
                    saf: false,
                    op: false,
                },
                concern: {},
                desc: "",
                note: "",
            },
            height: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            usemap: {
                browser: {
                    chr: false,
                    ie: false,
                    fx: true,
                    saf: false,
                    op: false,
                },
                concern: {},
                desc: "",
                note: "",
            },
            width: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    ol: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            reversed: {
                browser: {
                    chr: 18.0,
                    ie: 79.0,
                    fx: 18.0,
                    saf: 6.0,
                    op: 12.1,
                },
                concern: {},
                desc: "",
                note: "",
            },
            start: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    optgroup: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            disabled: {
                browser: {
                    chr: true,
                    ie: 8.0,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            label: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    option: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            disabled: {
                browser: {
                    chr: true,
                    ie: 8.0,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            label: {
                browser: {
                    chr: true,
                    ie: 8.0,
                    fx: false,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            selected: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            value: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    output: {
        browser: {
            chr: 10.0,
            ie: 13.0,
            fx: 4.0,
            saf: 5.1,
            op: 11.0,
        },
        attrs: {},
        for: {
            browser: {
                chr: 10.0,
                ie: 13.0,
                fx: 4.0,
                saf: 7.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
        form: {
            browser: {
                chr: false,
                ie: false,
                fx: false,
                saf: false,
                op: false,
            },
            concern: {},
            desc: "",
            note: "",
        },
        name: {
            browser: {
                chr: 10.0,
                ie: 13.0,
                fx: 4.0,
                saf: 7.0,
                op: 11.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
    },

    p: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    param: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            value: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    picture: {
        browser: {
            chr: 38.0,
            ie: 13.0,
            fx: 38.0,
            saf: 9.1,
            op: 25.0,
        },
        attrs: {},
    },

    pre: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    progress: {
        browser: {
            chr: 8.0,
            ie: 10.0,
            fx: 16.0,
            saf: 6.0,
            op: 11.0,
        },
        attrs: {},
        max: {
            browser: {
                chr: 8.0,
                ie: 10.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.0,
            },
            concern: {},
            desc: "",
            note: "",
        },
        value: {
            browser: {
                chr: 8.0,
                ie: 10.0,
                fx: 16.0,
                saf: 6.0,
                op: 11.0,
            },
            concern: {},
            desc: "",
            note: "",
        },
    },

    q: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            cite: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    rp: {
        browser: {
            chr: 5.0,
            ie: 5.5,
            fx: 38.0,
            saf: 5.0,
            op: 15.0,
        },
        attrs: {},
    },

    rt: {
        browser: {
            chr: 5.0,
            ie: 5.5,
            fx: 38.0,
            saf: 5.0,
            op: 15.0,
        },
        attrs: {},
    },

    ruby: {
        browser: {
            chr: 5.0,
            ie: 5.5,
            fx: 38.0,
            saf: 5.0,
            op: 15.0,
        },
        attrs: {},
    },

    s: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    samp: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    script: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            async: {
                browser: {
                    chr: 8.0,
                    ie: 10.0,
                    fx: 3.6,
                    saf: 5.1,
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            crossorigin: {
                browser: {
                    chr: 30.0,
                    ie: 18.0,
                    fx: 13.0,
                    saf: 13.0,
                    op: 12.1,
                },
                concern: {},
                desc: "",
                note: "",
            },
            defer: {
                browser: {
                    chr: 8.0,
                    ie: 10.0,
                    fx: 3.5,
                    saf: 5.0,
                    op: 15.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            integrity: {
                browser: {
                    chr: 45.0,
                    ie: 17.0,
                    fx: 43.0,
                    saf: 13.0,
                    op: 66.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            nomodule: {
                browser: {
                    chr: 61.0,
                    ie: 16.0,
                    fx: 60.0,
                    saf: 11.0,
                    op: 48.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
            referrerpolicy: {
                browser: {
                    chr: 70.0,
                    ie: 79.0,
                    fx: 65.0,
                    saf: false,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            src: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    section: {
        browser: {
            chr: 5.0,
            ie: 9.0,
            fx: 4.0,
            saf: 5.0,
            op: 11.5,
        },
        attrs: {},
    },

    select: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            autofocus: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: false,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            disabled: {
                browser: {
                    chr: true,
                    ie: 9.0,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            form: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            multiple: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            required: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: 4.0,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            size: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    slot: {
        global: {
            browser: {
                chr: 53.0,
                ie: 79.0,
                fx: 63.0,
                saf: 10.0,
                op: 40.0,
            },
            concern: {},
            desc: "",
            note: "",
        },
    },

    small: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    source: {
        browser: {
            chr: 4.0,
            ie: 9.0,
            fx: 3.5,
            saf: 4.0,
            op: 10.5,
        },
        attrs: {},
        media: {
            browser: {
                chr: 38.0,
                ie: 9.0,
                fx: 15.0,
                saf: 9.1,
                op: 25.0,
            },
            concern: {},
            desc: "",
            note: "",
        },
        sizes: {
            browser: {
                chr: 38.0,
                ie: 13.0,
                fx: 3.8,
                saf: 9.1,
                op: 25.0,
            },
            concern: {},
            desc: "",
            note: "",
        },
        src: {
            browser: {
                chr: 4.0,
                ie: 9.0,
                fx: 3.5,
                saf: 4.0,
                op: 10.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
        srcset: {
            browser: {
                chr: 38.0,
                ie: 13.0,
                fx: 38.0,
                saf: 9.1,
                op: 25.0,
            },
            concern: {},
            desc: "",
            note: "",
        },
        type: {
            browser: {
                chr: 4.0,
                ie: 9.0,
                fx: 3.5,
                saf: 4.0,
                op: 10.5,
            },
            concern: {},
            desc: "",
            note: "",
        },
    },

    span: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    strong: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    style: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            media: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            type: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    sub: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    summary: {
        browser: {
            chr: 12.0,
            ie: 79.0,
            fx: 49.0,
            saf: 6.0,
            op: 15.0,
        },
        attrs: {},
    },
    sup: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    svg: {
        browser: {
            chr: 4.0,
            ie: 9.0,
            fx: 3.0,
            saf: 3.2,
            op: 10.1,
        },
        attrs: {},
    },
    table: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    tbody: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    td: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            colspan: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            headers: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            rowspan: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    template: {
        browser: {
            chr: 26.0,
            ie: 13.0,
            fx: 22.0,
            saf: 8.0,
            op: 15.0,
        },
        attrs: {},
    },
    textarea: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            autocomplete: {
                browser: {
                    chr: false,
                    ie: false,
                    fx: 59.0,
                    saf: 13.0,
                    op: false,
                },
                concern: {},
                desc: "",
                note: "",
            },
            autofocus: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: 4.0,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            cols: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            dirname: {
                browser: {
                    chr: true,
                    ie: 79.0,
                    fx: false,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            disabled: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            form: {
                browser: {
                    chr: true,
                    ie: 11.0,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            maxlength: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: 4.0,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            minlength: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            name: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            placeholder: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: 4.0,
                    saf: 5.0,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            readonly: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            required: {
                browser: {
                    chr: true,
                    ie: 10.0,
                    fx: 4.0,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            rows: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            spellcheck: {
                browser: {
                    chr: true,
                    ie: 11.0,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            wrap: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    tfoot: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    th: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {
            abbr: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            colspan: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            headers: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            rowspan: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            scope: {
                browser: {
                    chr: true,
                    ie: true,
                    fx: true,
                    saf: true,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    thead: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    time: {
        browser: {
            chr: 62.0,
            ie: 18.0,
            fx: 22.0,
            saf: 7.0,
            op: 49.0,
        },
        attrs: {
            datetime: {
                browser: {
                    chr: 62.0,
                    ie: 18.0,
                    fx: 22.0,
                    saf: 7.0,
                    op: 49.0,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    title: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    tr: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    track: {
        browser: {
            chr: 23.0,
            ie: 10.0,
            fx: 31.0,
            saf: 6.0,
            op: 12.1,
        },
        attrs: {},
        default: {
            browser: {
                chr: 23.0,
                ie: 10.0,
                fx: 31.0,
                saf: 6.0,
                op: 12.1,
            },
            concern: {},
            desc: "",
            note: "",
        },
        kind: {
            browser: {
                chr: 23.0,
                ie: 10.0,
                fx: 31.0,
                saf: 6.0,
                op: 12.1,
            },
            concern: {},
            desc: "",
            note: "",
        },
        label: {
            browser: {
                chr: 23.0,
                ie: 10.0,
                fx: 31.0,
                saf: 6.0,
                op: 12.1,
            },
            concern: {},
            desc: "",
            note: "",
        },
        src: {
            browser: {
                chr: 23.0,
                ie: 10.0,
                fx: 31.0,
                saf: 6.0,
                op: 12.1,
            },
            concern: {},
            desc: "",
            note: "",
        },
        srclang: {
            browser: {
                chr: 23.0,
                ie: 10.0,
                fx: 31.0,
                saf: 6.0,
                op: 12.1,
            },
            concern: {},
            desc: "",
            note: "",
        },
    },

    u: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    ul: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    var: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
    video: {
        browser: {
            chr: 4.0,
            ie: 9.0,
            fx: 3.5,
            saf: 3.1,
            op: 11.5,
        },
        attrs: {
            autoplay: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 3.1,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            controls: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 3.1,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            height: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 3.1,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            loop: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 11.0,
                    saf: 3.1,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            muted: {
                browser: {
                    chr: 30.0,
                    ie: 10.0,
                    fx: 11.0,
                    saf: 5.0,
                    op: true,
                },
                concern: {},
                desc: "",
                note: "",
            },
            poster: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.6,
                    saf: 3.1,
                    op: 10.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            preload: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 4.0,
                    saf: 3.1,
                    op: 10.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            src: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 3.1,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
            width: {
                browser: {
                    chr: 4.0,
                    ie: 9.0,
                    fx: 3.5,
                    saf: 3.1,
                    op: 11.5,
                },
                concern: {},
                desc: "",
                note: "",
            },
        },
    },
    wbr: {
        browser: {
            chr: true,
            ie: true,
            fx: true,
            saf: true,
            op: true,
        },
        attrs: {},
    },
};
// export default tags_html_by_browser;
module.exports = tags_html_by_browser;
