let tags = {
    "!DOCTYPE": { desc: "Defines the document type", type: "Basic HTML" },
    html: { desc: "Defines an HTML document", type: "Basic HTML" },
    head: { desc: "Contains metadata/information for the document", type: "Basic HTML" },
    title: { desc: " Defines a title for the document", type: "Basic HTML" },
    body: { desc: "Defines the descnt's body", type: "Basic HTML" },
    h1: { desc: "Defines HTML headings size 1", type: "Basic HTML" },
    h2: { desc: "Defines HTML headings size 2", type: "Basic HTML" },
    h3: { desc: "Defines HTML headings size 3", type: "Basic HTML" },
    h4: { desc: "Defines HTML headings size 4", type: "Basic HTML" },
    h5: { desc: "Defines HTML headings size 5", type: "Basic HTML" },
    h6: { desc: "Defines HTML headings size 6", type: "Basic HTML" },
    p: { desc: " Defines a paragraph", type: "Basic HTML" },
    br: { desc: "Inserts a single line break", type: "Basic HTML" },
    hr: { desc: "Defines a thematic change in the content", type: "Basic HTML" },
    "!--...--": { desc: "Defines a comment", type: "Basic HTML" },
    acronym: { desc: "Defines an acronym", note: " Not supported in HTML5. Use <abbr> instead.", type: "Formatting" },
    abbr: { desc: "Defines an abbreviation or an acronym", type: "Formatting" },
    address: { desc: " Defines contact information for the author/owner of a document/article", type: "Formatting" },
    b: { desc: " Defines bold text", type: "Formatting" },
    bdi: {
        desc: " Isolates a part of text that might be formatted in a different direction from other text outside it",
        type: "Formatting",
    },
    bdo: { desc: " Overrides the current text direction", type: "Formatting" },
    big: { desc: "Defines big text", note: " Not supported in HTML5. Use CSS instead.", type: "Formatting" },
    blockquote: { desc: "Defines a section that is quoted from another source", type: "Formatting" },
    center: { desc: "Defines centered text", note: "Not supported in HTML5. Use CSS instead.", type: "Formatting" },
    cite: { desc: "Defines the title of a work", type: "Formatting" },
    code: { desc: "Defines a piece of computer code", type: "Formatting" },
    del: { desc: " Defines text that has been deleted from a document", type: "Formatting" },
    dfn: { desc: " Specifies a term that is going to be defined within the content", type: "Formatting" },
    em: { desc: "Defines emphasized text ", type: "Formatting" },
    font: {
        desc: "Defines font, color, and size for text",
        note: "Not supported in HTML5. Use CSS instead.",
        type: "Formatting",
    },
    i: { desc: " Defines a part of text in an alternate voice or mood", type: "Formatting" },
    ins: { desc: " Defines a text that has been inserted into a document", type: "Formatting" },
    kbd: { desc: " Defines keyboard input", type: "Formatting" },
    mark: { desc: "Defines marked/highlighted text", type: "Formatting" },
    meter: { desc: " Defines a scalar measurement within a known range (a gauge)", type: "Formatting" },
    pre: { desc: " Defines preformatted text", type: "Formatting" },
    progress: { desc: "Represents the progress of a task", type: "Formatting" },
    q: { desc: " Defines a short quotation", type: "Formatting" },
    rp: { desc: "Defines what to show in browsers that do not support ruby annotations", type: "Formatting" },
    rt: { desc: "Defines an explanation/pronunciation of characters (for East Asian typography)", type: "Formatting" },
    ruby: { desc: "Defines a ruby annotation (for East Asian typography)", type: "Formatting" },
    s: { desc: " Defines text that is no longer correct", type: "Formatting" },
    samp: { desc: "Defines sample output from a computer program", type: "Formatting" },
    small: { desc: " Defines smaller text", type: "Formatting" },
    strike: {
        desc: "Defines strikethrough text",
        note: "Not supported in HTML5. Use <del> or <s> instead.",
        type: "Formatting",
    },
    strong: { desc: "Defines important text", type: "Formatting" },
    sub: { desc: " Defines subscripted text", type: "Formatting" },
    sup: { desc: " Defines superscripted text", type: "Formatting" },
    template: { desc: "Defines a container for content that should be hidden when the page loads", type: "Formatting" },
    time: { desc: "Defines a specific time (or datetime)", type: "Formatting" },
    tt: { desc: "Defines teletype text", note: "Not supported in HTML5. Use CSS instead.", type: "Formatting" },
    u: { desc: " Defines some text that is unarticulated and styled differently from normal text", type: "Formatting" },
    var: { desc: " Defines a variable", type: "Formatting" },
    wbr: { desc: " Defines a possible line-break", type: "Formatting" },
    form: { desc: "Defines an HTML form for user input", type: "Forms and Input" },
    input: { desc: " Defines an input control", type: "Forms and Input" },
    textarea: { desc: "Defines a multiline input control (text area)", type: "Forms and Input" },
    button: { desc: "Defines a clickable button", type: "Forms and Input" },
    select: { desc: "Defines a drop-down list", type: "Forms and Input" },
    optgroup: { desc: "Defines a group of related options in a drop-down list", type: "Forms and Input" },
    option: { desc: "Defines an option in a drop-down list", type: "Forms and Input" },
    label: { desc: " Defines a label for an <input> element", type: "Forms and Input" },
    fieldset: { desc: "Groups related elements in a form", type: "Forms and Input" },
    legend: { desc: "Defines a caption for a <fieldset> element", type: "Forms and Input" },
    datalist: { desc: "Specifies a list of pre-defined options for input controls", type: "Forms and Input" },
    output: { desc: "Defines the result of a calculation", type: "Forms and Input" },
    frame: { note: "Not supported in HTML5", desc: "Defines a window (a frame) in a frameset", type: "Frames" },
    frameset: { desc: "Defines a set of frames", note: "Not supported in HTML5.", type: "Frames" },
    noframes: {
        desc: "Defines an alternate content for users that do not support frames",
        note: "Not supported in HTML5.",
        type: "Frames",
    },
    iframe: { desc: "Defines an inline frame", type: "Frames" },
    img: { desc: " Defines an image", type: "Images" },
    map: { desc: " Defines a client-side image map", type: "Images" },
    area: { desc: "Defines an area inside an image map", type: "Images" },
    canvas: { desc: "Used to draw graphics, on the fly, via scripting (usually JavaScript)", type: "Images" },
    figcaption: { desc: "Defines a caption for a <figure> element", type: "Images" },
    figure: { desc: "Specifies self-contained content", type: "Images" },
    picture: { desc: " Defines a container for multiple image resources", type: "Images" },
    svg: { desc: " Defines a container for SVG graphics", type: "Images" },
    audio: { desc: " Defines sound content", type: "Audio / Video" },
    source: {
        desc: "Defines multiple media resources for media elements (<video>, <audio> and <picture>)",
        type: "Audio / Video",
    },
    track: { desc: " Defines text tracks for media elements (<video> and <audio>)", type: "Audio / Video" },
    video: { desc: " Defines a video or movie", type: "Audio / Video" },
    a: { desc: " Defines a hyperlink", type: "Links" },
    link: {
        desc: "Defines the relationship between a document and an external resource (most used to link to style sheets)",
        type: "Links",
    },
    nav: { desc: " Defines navigation links", type: "Links" },
    ul: { desc: "Defines an unordered list", type: "Lists" },
    ol: { desc: "Defines an ordered list", type: "Lists" },
    li: { desc: "Defines a list item", type: "Lists" },
    dir: { desc: "Defines a directory list", note: " Not supported in HTML5. Use <ul> instead.", type: "Lists" },
    dl: { desc: "Defines a description list", type: "Lists" },
    dt: { desc: "Defines a term/name in a description list", type: "Lists" },
    dd: { desc: "Defines a description of a term/name in a description list", type: "Lists" },
    table: { desc: " Defines a table", type: "Tables" },
    caption: { desc: " Defines a table caption", type: "Tables" },
    th: { desc: "Defines a header cell in a table", type: "Tables" },
    tr: { desc: "Defines a row in a table", type: "Tables" },
    td: { desc: "Defines a cell in a table", type: "Tables" },
    thead: { desc: "Groups the header content in a table", type: "Tables" },
    tbody: { desc: "Groups the body content in a table", type: "Tables" },
    tfoot: { desc: "Groups the footer content in a table", type: "Tables" },
    col: { desc: " Specifies column properties for each column within a <colgroup> element", type: "Tables" },
    colgroup: { desc: "Specifies a group of one or more columns in a table for formatting", type: "Tables" },
    style: { desc: " Defines style information for a document", type: "Styles and Semantics" },
    div: { desc: " Defines a section in a document", type: "Styles and Semantics" },
    span: { desc: "Defines a section in a document", type: "Styles and Semantics" },
    header: { desc: "Defines a header for a document or section", type: "Styles and Semantics" },
    footer: { desc: "Defines a footer for a document or section", type: "Styles and Semantics" },
    main: { desc: "Specifies the main content of a document", type: "Styles and Semantics" },
    section: { desc: " Defines a section in a document", type: "Styles and Semantics" },
    article: { desc: " Defines an article", type: "Styles and Semantics" },
    aside: { desc: " Defines content aside from the page content", type: "Styles and Semantics" },
    details: { desc: " Defines additional details that the user can view or hide", type: "Styles and Semantics" },
    dialog: { desc: "Defines a dialog box or window", type: "Styles and Semantics" },
    summary: { desc: " Defines a visible heading for a <details> element", type: "Styles and Semantics" },
    data: { desc: "Adds a machine-readable translation of a given content", type: "Styles and Semantics" },
    head: { desc: "Defines information about the document", type: "Meta Info" },
    meta: { desc: "Defines metadata about an HTML document", type: "Meta Info" },
    base: { desc: "Specifies the base URL/target for all relative URLs in a document", type: "Meta Info" },
    basefont: {
        desc: "Specifies a default color, size, and font for all text in a document",
        note: "Not supported in HTML5. Use CSS instead.",
        type: "Meta Info",
    },
    script: { desc: "Defines a client-side script", type: "Programming" },
    noscript: {
        desc: "Defines an alternate content for users that do not support client-side scripts",
        type: "Programming",
    },
    applet: {
        desc: "Defines an embedded applet",
        note: "Not supported in HTML5. Use <embed> or <object> instead.",
        type: "Programming",
    },
    embed: { desc: " Defines a container for an external (non-HTML) application", type: "Programming" },
    object: { desc: "Defines an embedded object", type: "Programming" },
    param: { desc: " Defines a parameter for an object", type: "Programming" },
};

// export default tags;
module.exports = tags;
