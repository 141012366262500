import Vue from "vue";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: "mdi",
        // iconfont: 'mdiSvg'
    },
    theme: {
        themes: {
            light: {},
            dark: {
                primary: colors.indigo.base,
            },
        },
    },
};

export default new Vuetify(opts);
